<template>
  <v-col cols="12" md="3">
    <div class="payment-container">
      <h3 class="cart-title">Payment</h3>
      <div class="payment-details">
        <v-row style="margin-bottom: 8px">
          <v-col cols="12" sm="6" class="total-amount">Value in Points</v-col>
          <v-col cols="12" sm="6" class="value"> {{ allAmount }} pts </v-col>
        </v-row>
        <hr style="opacity: 0.3" />

        <div v-if="isFound">
          <v-row style="margin-top: 8px">
            <v-col cols="12" sm="2" class="attr-text">Name</v-col>
            <v-col cols="12" sm="10" class="attr-value text-right">{{
              studentData.name
            }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="attr-text">Current Credit</v-col>
            <v-col
              cols="12"
              sm="6"
              class="attr-value text-right"
              :style="insuffecient ? 'color: #FF785A' : ''"
              >{{ studentData.current_cerdit }} pts</v-col
            >
          </v-row>
          <v-row style="margin-bottom: 8px">
            <v-col cols="12" sm="6" class="attr-text">Remaining Credit</v-col>
            <v-col
              cols="12"
              sm="6"
              class="attr-value text-right"
              :style="insuffecient ? 'color: #FF785A' : ''"
            >
              {{ reminingCurrent }} pts</v-col
            >
          </v-row>
          <hr style="opacity: 0.3" />
        </div>

        <v-form ref="ScanQR" v-model="valid" class="mt-5">
          <v-row style="padding: 12px">
            <v-text-field
              class="check-input"
              v-model="studentQR"
              label="Scan QR Code or enter Card Number"
              solo
              @keydown.enter.prevent="scanQRCode(studentQR)"
              :rules="[validationRules.required]"
            ></v-text-field>
            <v-btn
              style="color: #ffffff; text-transform: capitalize"
              class="main-btn"
              @click="scanQRCode"
            >
              Check Balance
            </v-btn>
            <div class="error-div" v-if="isNotFound">Code not found</div>
          </v-row>
        </v-form>
      </div>
      <div class="insufficient" v-if="insuffecient">
        <p>
          <b>Insufficient funds!</b> To continue, please remove items from cart
          or scan/enter another code.
        </p>
      </div>
      <v-btn
        class="checkout-btn main-btn"
        :loading="loading"
        :disabled="
          loading || (isFound && !insuffecient && allAmount > 0) ? false : true
        "
        @click="checkoutOrders"
      >
        Checkout
        <template v-slot:loader>
          <span>Loading now...</span>
        </template>
      </v-btn>
    </div>
    <!--  -->
    <div class="container-btn">
      <v-btn
        outlined
        color="#7297FF"
        style="
          text-align: right;
          position: absolute;
          right: -11px;
          margin-top: 50px;
        "
        class="disregard-btn"
        @click="disregard()"
        >Disregard Order</v-btn
      >
    </div>
    <!--  -->
    <!-- v-model="snackbar" -->
    <v-snackbar
      v-model="snackbar"
      :color="snack_color"
      rounded="pill"
      top
      centered
    >
      <h3 style="text-align: center; color: #ffffff !important">
        Order Placed Successfuly
      </h3>
    </v-snackbar>
    <!-- ---------------------------------------------------------------------- -->
    <v-row justify="center">
      <v-dialog v-model="confirm_dialog" max-width="220">
        <v-card class="container">
          <v-card-title class="text-h6" style="text-align: center">
            Confirmation
          </v-card-title>
          <v-spacer></v-spacer>
          <div class="" style="text-align: center">
            <v-btn
              class="alert-cancel-btn buttons"
              text
              @click="confirm_dialog = false"
            >
              Return to Order
            </v-btn>
            <br /><br />
            <v-btn
              class="alert-save-btn buttons"
              text
              @click="confirmDisregardOrder"
            >
              Disregard Order
            </v-btn>
            <br /><br />
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
export default {
  mixins: [validationMixin],
  name: "PaymentComponent",
  props: ["allAmount", "orderDetails"],
  data() {
    return {
      producItems: "",
      snackbar: false,
      timeout: 10000,
      snack_color: "success",
      totalAmount: "",
      isFound: false,
      isNotFound: false,
      loading: false,
      studentQR: "",
      valid: true,
      disabled: true,
      studentData: {
        name: "",
        current_cerdit: 0,
        current_amount: 0,
        user_id: "",
        student_id: "",
      },
      insuffecient: false,
      orderData: {
        user_id: "",
        student_id: "",
        order_number: "",
        orderItems: [],
      },
      dialog: false,
      // message: "",
      confirm_dialog: false,
    };
  },
  methods: {
    scanQRCode() {
      this.isNotFound = false;
      this.isFound = false;
      HttpService.get("orders/getStudent/", this.studentQR, true).then(
        (response) => {
          console.log("payment res = ", response);
          if (response.data.data) {
            this.isFound = true;
            this.studentData.name = response.data.data.name;
            this.studentData.current_cerdit = Number(
              response.data.data.current_cerdit
            ).toFixed(2);
            this.studentData.current_amount = Number(
              response.data.data.current_amount
            ).toFixed(2);
            this.studentData.user_id = response.data.data.user_id;
            this.studentData.student_id = response.data.data.student_id;
            this.reminingCurrent = Number(
              response.data.data.current_cerdit
            ).toFixed(2);
          } else {
            this.isNotFound = true;
            //
          }
        }
      );
    },

    checkoutOrders() {
      this.loading = true;
      this.orderData.user_id = this.studentData.user_id;
      this.orderData.student_id = this.studentData.student_id;
      this.orderData.orderItems = this.orderDetails;
      // console.log("orderData", this.orderData);
      // console.log("orderData.ordeItems", this.orderData.orderItems);
      HttpService.post("orders/checkoutProcessing", this.orderData, true).then(
        (response) => {
          console.log("res= ", response);
          this.snack_color = "success";
          this.snackbar = true;
          setTimeout(function () {
            window.location.reload();
            this.loading = false;
          }, 5000);
        }
      );
    },

    disregard() {
      this.confirm_dialog = true;
    },

    confirmDisregardOrder() {
      console.log("this.$router.reloa");
      window.location.reload();
    },
  },

  computed: {
    reminingCurrent() {
      // console.log("hhhh", this.studentData.current_cerdit, this.allAmount);
      console.log("studentData", this.studentData);

      let remaining = this.studentData.current_cerdit - this.allAmount;
      let remainingAmount = this.studentData.current_amount - this.allAmount;
      if (remaining < 0 || remainingAmount < 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.insuffecient = true;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.insuffecient = false;
      }
      return Number(remaining).toFixed(2);
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: -20px;
}

.payment-container {
  padding: 25px;
  width: 486px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.cart-title {
  margin-bottom: 20px;
  width: 95%;
  margin: 0 auto 30px auto;
}

.payment-details {
  background: #f9f9f9;
  width: 95%;
  /* height: 70%; */
  margin: 0 auto;
  padding: 20px;
}

.total-amount {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: black;
}

.value {
  text-align: right;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #43425d;
}

.attr-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #707070;
}

.attr-value {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #979797;
}

.check-input {
  width: 60% !important;
  border-radius: 9px 0 0 9px;
}

.main-btn {
  height: 48px !important;
  border-radius: 0 4px 4px 0px;
}

.checkout-btn {
  border-radius: 4px;
  width: 95%;
  margin: 20px auto;
  display: block;
  color: #ffffff;
  text-transform: capitalize;
}

.insufficient {
  /* background-color: #ffcfc5; */
  margin: 10px;
  padding: 10px;
  /* gap: 10px; */

  background: #ffcfc5;
  border-radius: 8px;
}

.error-div {
  color: red;
  margin-bottom: 10px;
}
/* ---------------------------- */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.text-h6 {
  color: #7297ff;
  margin-left: auto;
  margin-right: auto;
  width: 9em;
}

.container {
  height: 200px;
  position: relative;
  border: 3px solid green;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.disregard-btn {
  text-transform: capitalize;
  /* position: fixed;
  right: 55px;
  bottom: 100px;
  padding: 5px; */
}
</style>
