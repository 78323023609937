<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="data-table">
    <v-data-table
      :headers="table.headersData"
      :items="items"
      sort-by="calories"
      class="elevation-1 level1"
      :options.sync="options"
      :loading="loading"
      v-model="selected"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row class="headerTable-div">
          <!-- TITLE Here -->
          <v-col md="4" sm="12">
            <h3 class="custom-header main-color">{{ table.title + "s" }}</h3>
          </v-col>
          <!-- ==================================================filters================================================== -->
          <v-col cols="12" md="4" sm="12">
            <v-form ref="modalForm" v-model="valid">
              <!-- <div > -->
              <!-- <v-col cols="2"> -->
              <div class="searchBox" v-if="showSearch">
                <input
                  v-model="payload.keyword"
                  label="Enter Item name"
                  class="searchInput"
                  solo
                  @keyup="Search()"
                />
                <button class="searchButton" href="#">
                  <i class="material-icons"> search </i>
                </button>
              </div>
              <!-- </v-col> -->
              <!-- </div> -->
            </v-form>
          </v-col>
          <!-- ==================================================filters================================================== -->
          <!--ADD Button Here -->
          <v-col md="4" sm="12" class="text-right">
            <v-icon
              large
              @click="OpenDialogAddEdit"
              class="main-color"
              :title="$t('Add New')"
              v-if="showCreateBtn"
              >add_circle
            </v-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="28"
              viewBox="0 0 25 28"
              fill="none"
              v-if="showCalenderBtn"
            >
              <path
                d="M8.33333 12.6H5.55556V15.4H8.33333V12.6ZM13.8889 12.6H11.1111V15.4H13.8889V12.6ZM19.4444 12.6H16.6667V15.4H19.4444V12.6ZM22.2222 2.8H20.8333V0H18.0556V2.8H6.94444V0H4.16667V2.8H2.77778C1.23611 2.8 0.0138889 4.06 0.0138889 5.6L0 25.2C0 25.9426 0.292658 26.6548 0.813592 27.1799C1.33453 27.705 2.04107 28 2.77778 28H22.2222C23.75 28 25 26.74 25 25.2V5.6C25 4.06 23.75 2.8 22.2222 2.8ZM22.2222 25.2H2.77778V9.8H22.2222V25.2Z"
                fill="#5A86FF"
              />
            </svg>
          </v-col>
          <!-- ==================================================Show Students================================================== -->
        </v-row>
        <div v-if="showStudents">
          <v-btn
            :class="selectedStudentID == child.id ? 'isBlue' : ''"
            v-for="child in children"
            :key="child"
            rounded
            color="#EFEFEF"
            dark
            style="
              margin: 10px;
              margin-left: 0px;
              text-transform: capitalize;
              color: #979797;
              width: auto;
            "
            @click="whoSelected(child)"
          >
            {{ child.name }}
          </v-btn>
        </div>
        <!-- ==================================================Order Number Link================================================== -->
      </template>

      <template v-slot:[`item.order_number`]="{ item }">
        <router-link
          :to="
            '/OrderDetails/' + item.id + orderDetailsData + item.order_number
          "
        >
          {{ item.order_number }}</router-link
        >
      </template>
      <!-- -------------------------------------------------------------------- -->
      <template
        v-slot:[`item.plain_password`]="{ item }"
        v-if="table.parent == 'staff-view'"
      >
        <p v-if="item.plain_password && !item.show_password">******</p>
        <p v-else>{{ item.plain_password }}</p>
      </template>
      <!-- -------------------------------------------------------------------- -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="mr-2"
          @click="editItem(item)"
          v-if="showEditBtn"
          title="Edit"
        >
          edit
        </v-icon>
        <!-- ................................................. -->
        <v-icon
          class="mr-2"
          @click="deleteItem(item)"
          v-if="showDeleteBtn"
          title="Delete"
        >
          delete
        </v-icon>
        <!-- ------------------------------------------------------ -->
        <v-switch
          :title="
            item.active
              ? table.activation.isActive
              : table.activation.isInactive
          "
          v-model="item.active"
          class="mr-2"
          @click="deactivate(item)"
          v-if="showDeactivate"
        ></v-switch>
        <!-- ----------------------------------------------------- -->
        <v-icon
          class="show-password-icon mr-2"
          v-if="table.parent == 'staff-view' && !item.show_password"
          title="Show Password"
          @click="item.show_password = true"
          >mdi-eye</v-icon
        >
        <v-icon
          class="show-password-icon mr-2"
          v-if="table.parent == 'staff-view' && item.show_password"
          title="Hide Password"
          @click="item.show_password = false"
          >mdi-eye-off</v-icon
        >
      </template>

      <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template> -->
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.length"
        :circle="true"
        :page="pagination.page"
        :total-visible="pagination.totalVisible"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to delete {{ item_name }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="alert-cancel-btn" text @click="dialogDelete = false">
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="confirmDeleteItem">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <AddNewProduct
      v-if="table.parent == 'product-view'"
      ref="addEditComponent"
      :editedIndex="editedIndex"
      :addEditDialog="dialog"
      v-on:closeDialog="close()"
      v-on:getData="getDataFromApi()"
    ></AddNewProduct>

    <AddNewCanteenDialog
      v-if="table.parent == 'canteen-view'"
      ref="addEditComponent"
      :editedIndex="editedIndex"
      :addEditDialog="dialog"
      v-on:closeDialog="close()"
      v-on:getData="getDataFromApi()"
    ></AddNewCanteenDialog>

    <AddNewCanteenStaffMember
      v-if="table.parent == 'staff-view'"
      ref="addEditComponent"
      :editedIndex="editedIndex"
      :addEditDialog="dialog"
      v-on:closeDialog="close()"
      v-on:getData="getDataFromApi()"
    ></AddNewCanteenStaffMember>

    <AddNewAccountantMember
      v-if="table.parent == 'accountant-staff-view'"
      ref="addEditComponent"
      :editedIndex="editedIndex"
      :addEditDialog="dialog"
      v-on:closeDialog="close()"
      v-on:getData="getDataFromApi()"
    >
    </AddNewAccountantMember>

    <AddNewStoreCanteenProducts
      v-if="table.parent == 'store-view'"
      ref="addEditComponent"
      :editedIndex="editedIndex"
      :addEditDialog="dialog"
      v-on:closeDialog="close()"
      v-on:getData="getDataFromApi()"
    ></AddNewStoreCanteenProducts>
  </div>
</template>

<script>
import HttpService from "../services/HttpService.js";
import AddNewCanteenDialog from "./AddNewCanteenDialog.vue";
import AddNewCanteenStaffMember from "./AddNewCanteenStaffMember.vue";
import AddNewProduct from "./AddNewProduct.vue";
import AddNewStoreCanteenProducts from "./AddNewStoreCanteenProducts.vue";
import AddNewAccountantMember from "./AddNewAccountantMember.vue";

export default {
  name: "DataTableComponent",
  props: ["table", "children", "selectedStudentID"],
  components: {
    AddNewCanteenDialog,
    AddNewCanteenStaffMember,
    AddNewProduct,
    AddNewStoreCanteenProducts,
    AddNewAccountantMember,
  },
  data: () => ({
    loading: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    pagination: {
      page: 1,
      length: 0,
      totalVisible: "",
    },
    options: {
      itemsPerPage: 15,
    },
    selected: [],
    items: [],
    order_id: 0,
    orderDetailsData: "",
    changeColor: false,
    searchPaylod: "",
    searched_items: "",
    payload: {
      keyword: "",
    },
    deletedItem: "",
    // status: false,
    item_name: "",
  }),

  methods: {
    getDataFromApi() {
      let queryString = "?page=" + this.pagination.page;
      HttpService.get(this.table.endpoints.list, queryString, true).then(
        (response) => {
          // console.log("response = ", response);
          this.setPaginationParameters(response);
          this.items = response.data.data.data;
        }
      );
    },

    getDataHistory() {
      let queryString = "?page=" + this.pagination.page;
      HttpService.get(
        "wallet/Studentwallet/orders/" + this.selectedStudentID,
        queryString,
        true
      ).then((response) => {
        console.log("res of getDataHistory = ", response);
        this.setPaginationParameters(response);
        this.items = response.data.data.data;
        for (var i = 0; i < this.items.length; i++) {
          this.items[i].total = Math.round(this.items[i].total) + "  pts";
        }
        // console.log("i came frome getDataHistory() ");
      });
    },

    whoSelected(child) {
      // console.log("child = ", child);
      this.selectedStudentID = child.id;
      this.orderDetailsData = "," + child.name + ",";
      this.changeColor = !this.changeColor;
    },

    setPaginationParameters(response) {
      this.pagination.length = response.data.data.last_page;
      if (this.pagination.length > 5) this.pagination.totalVisible = 5;
    },

    OpenDialogAddEdit() {
      this.dialog = true;
    },

    editItem(item) {
      this.$refs.addEditComponent.setEditedItem(item);
      this.editedIndex = item.id;
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedItem = item;
      this.item_name = item.en.name;
      this.dialogDelete = true;
    },

    confirmDeleteItem() {
      this.dialogDelete = false;
      this.$emit("deletedItem", this.deletedItem);
    },

    deactivate(item) {
      console.log("item.active ", item.active);
      this.$emit("deactivatedItem", item);
    },

    inputFocus() {
      // eslint-disable-next-line no-undef
      $("#inpt_search").parent("label").addClass("active");
      // eslint-disable-next-line no-undef
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.payload.keyword == "") {
        // eslint-disable-next-line no-undef
        $("#inpt_search").parent("label").removeClass("active");
        // eslint-disable-next-line no-undef
        $(".input_search").parent("label").removeClass("active");
      }
    },

    show(item) {
      this.order_id = item.id;
      this.$router.push({
        path: "/OrderDetails/" + this.order_id,
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    Search() {
      if (this.valid) {
        if (this.payload.keyword != "") {
          HttpService.post("products/keyword-search/", this.payload, true).then(
            (response) => {
              if (response.data.status.error == false) {
                this.searched_items = response.data.data;
                this.items = response.data.data;
              } else {
                // if there's an error
                if (
                  Object.keys(response.data.status.validation_errors).length > 0
                ) {
                  // if there's validation errors from backend
                  this.validation_errors =
                    response.data.status.validation_errors;
                } else {
                  // if there's an error but it's not validation error
                }
              }
            }
          );
        } else {
          this.getDataFromApi();
        }
      }
    },
  },

  created() {
    this.getDataFromApi();

    if (this.table.model == "history") {
      //  i.e Parent is logged in
      HttpService.get("wallet/myChildren", "", true).then((response) => {
        this.children = response.data.data;
        if (this.selectedStudentID > -1) {
          //لو الاب اختار ابن معين
          var selectedStudentName = this.children.find(
            (element) => element.id == this.selectedStudentID
          ).name;
          this.orderDetailsData = "," + selectedStudentName + ",";
        } else {
          // لو مختارش حد يبقي default
          this.selectedStudentID = this.children[0].id;
          this.orderDetailsData = "," + this.children[0].name + ",";
          console.log("i came frome created() under page");
        }
      });
    } else {
      //  i.e Student is logged in
      this.selectedStudentID = this.children[0].id;
      this.orderDetailsData = "," + this.children[0].name + ",";
    }
  },

  watch: {
    "pagination.page": {
      handler() {
        if (this.table.model != "history") {
          this.getDataFromApi();
        } else {
          this.getDataHistory();
        }
      },
      deep: true,
    },

    selectedStudentID: {
      handler() {
        this.getDataHistory();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
  },

  computed: {
    showCreateBtn() {
      return this.table.endpoints.create != undefined;
    },
    showEditBtn() {
      return this.table.endpoints.update != undefined;
    },
    showDeleteBtn() {
      return this.table.endpoints.delete != undefined;
    },
    showCalenderBtn() {
      return this.table.endpoints.calender != undefined;
    },
    showStudents() {
      return this.table.endpoints.children != undefined;
    },
    showSearch() {
      return this.table.endpoints.search != undefined;
    },
    showDeactivate() {
      return this.table.endpoints.deactivate != undefined;
    },
  },

  mounted() {},
};
</script>

<!-- <style > -->

<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_modals.scss";
.isBlue {
  color: #5a86ff !important;
  background-color: rgba(90, 134, 255, 0.3) !important;
}
.searchInput {
  border-radius: 30px;
  padding: 10px;
}

/////////////////////////////SEARCH BOX ////////////////////////////
.searchBox {
  position: absolute;
  background: #eaeefc;
  border-radius: 40px;
  padding: 10px;
}

.searchBox:hover > .searchInput {
  width: 240px;
  padding: 0 6px;
}

.searchBox:hover > .searchButton {
  background: white;
}

.searchButton {
  color: white;
  // float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // transition: 0.4s;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: grey;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
  }
}
</style>
