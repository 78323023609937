<template>
  <v-container>
    <v-row class="headerTable-div2" style="width: 50%">
      <v-col md="4" sm="12">
        <h3 class="custom-header main-color">{{ title }}</h3>
      </v-col>
    </v-row>
    <!-- Children -->
    <v-btn
      :class="selectedStudentID == child.id ? 'isBlue' : ''"
      v-for="child in children"
      :key="child"
      rounded
      color="#EFEFEF"
      dark
      style="
        margin: 10px;
        margin-left: 0px;
        text-transform: capitalize;
        color: #979797;
        width: auto;
      "
      @click="whoSelected(child)"
    >
      {{ child.name }}
    </v-btn>
    <!-- Children -->
    <v-row>
      <v-col cols="6" style="position: relative">
        <v-data-table
          :headers="table.headersData"
          :items="dataTable"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
      <EmptySpace></EmptySpace>
    </v-row>
  </v-container>
</template>

<script>
import EmptySpace from "../components/EmptySpace.vue";
import HttpService from "../services/HttpService.js";

export default {
  components: {
    EmptySpace,
  },
  props: ["order"],

  data() {
    return {
      children: 0,
      dataTable: [],
      selectedStudentID: -1,
      title: "Transfers",
      table: {
        headersData: [
          {
            text: this.$i18n.t("Amount"),
            value: "points",
            sortable: false,
          },
          {
            text: this.$i18n.t("Created at"),
            value: "created_at",
            sortable: false,
          },
          {
            text: this.$i18n.t("Transferd by"),
            value: "transformer_from.name",
            sortable: false,
          },
        ],
      },
    };
  },
  methods: {
    whoSelected(child) {
      console.log(child);
      this.selectedStudentID = child.id;
      this.getAllTransfers();
    },
    getChildren() {
      HttpService.get("wallet/myChildren", "", true).then((response) => {
        this.children = response.data.data;
        this.selectedStudentID = this.children[0].id;
        this.getAllTransfers();
      });
    },

    getAllTransfers() {
      HttpService.get(
        "wallet/allTransfers/" + this.selectedStudentID,
        "",
        true
      ).then((response) => {
        this.dataTable = response.data.data.allTransfers;
        console.log("dataTable is = ", this.dataTable);
      });
    },
  },
  created() {
    this.getChildren();
  },

  mounted() {},
};
</script>

<style>
.isBlue {
  color: #5a86ff !important;
  background-color: rgba(90, 134, 255, 0.3) !important;
}

.searchInput {
  border-radius: 30px;
  padding: 10px;
  /* width: 793px;
  height: 51px; */
}
</style>
