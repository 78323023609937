<template>
  <v-container>
    <v-row class="">
      <v-col cols="12">
        <DataTableComponent :table="tableData"></DataTableComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableComponent from "../components/DataTableComponent.vue";
export default {
  name: "Stores",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      tableData: {
        parent: "store-view",
        title: "Quantitie",
        headersData: [
          {
            text: this.$i18n.t("Product Name"),
            value: "en.name",
            width: "15%",
          },
          {
            text: this.$i18n.t("Quantity"),
            value: "quantity",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$i18n.t(" Buying Price per Unit"),
            value: "buying_price",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$i18n.t("Canteen "),
            value: "canteen_name",
            sortable: false,
            width: "15%",
          },
          // {
          //   text: this.$i18n.t("Actions"),
          //   value: "actions",
          //   sortable: false,
          //   width: "12%",
          // },
        ],
        endpoints: {
          list: "stores",
          create: "stores",
          update: "stores",
          // delete: "stores",
        },
      },
    };
  },
};
</script>

<style></style>

<!-- AddNewStoreCanteenProducts -->
