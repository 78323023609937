<template>
  <v-container>
    <v-row class="">
      <v-col cols="3"></v-col>
      <v-col class="mb-4" cols="6">
        <SettingsForm />
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsForm from "../components/SettingsUpdate.vue";
export default {
  data() {
    return {
      //
    };
  },
  components: {
    SettingsForm,
  },
};
</script>

<style></style>
