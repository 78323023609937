<template>
  <v-app>
    <v-main>
      <!-- If Logged In -->
      <div class="" id="content" v-if="isLoggedIn">
        <NavBar></NavBar>
        <SideBar></SideBar>
        <v-container id="" fluid>
          <router-view />
        </v-container>
        <Footer></Footer>
      </div>

      <!-- If Not Logged In -->
      <div class="" id="content" v-else>
        <v-container id="" fluid>
          <router-view />
        </v-container>
        <!-- <Footer></Footer> -->
      </div>
    </v-main>
  </v-app>
</template>

<script>
import ACL from "./acl.js";
import NavBar from "./layout/NavBar.vue";
import SideBar from "./layout/SideBar.vue";
import Footer from "./layout/Footer.vue";
export default {
  name: "App",
  data: () => ({
    isLoggedIn: ACL.isLoggedIn(),
  }),

  components: {
    NavBar,
    Footer,
    SideBar,
  },
};
</script>
