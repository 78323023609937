<template>
  <h1>Home View</h1>
  <!-- <DT></DT> -->
</template>

<script>
// import DT from "../components/DT.vue";
export default {
  components: {
    // DT,
  },
};
</script>

<style></style>
