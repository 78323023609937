<template>
  <v-container>
    <v-row>
      <Children
        :students="students"
        :currentBalance="currentBalance"
        :currentPoints="currentPoints"
        v-on:reload="getDataFromApi"
      ></Children>

      <EmptySpace></EmptySpace>

      <MyWallet
        :currentBalance="currentBalance"
        :currentPoints="currentPoints"
        :activateRecharge="activateRecharge"
      ></MyWallet>
    </v-row>
  </v-container>
</template>

<script>
import Children from "../components/Children.vue";
import MyWallet from "../components/MyWallet.vue";
import EmptySpace from "../components/EmptySpace.vue";
import HttpService from "../services/HttpService.js";
export default {
  name: "Wallet",
  components: {
    Children,
    MyWallet,
    EmptySpace,
  },
  data() {
    return {
      type: "me",
      currentBalance: 0,
      currentPoints: 0,
      students: [],
      activateRecharge: true,
    };
  },
  methods: {
    getDataFromApi() {
      HttpService.get("wallet/getWalletDetailsByJoin", "", true).then(
        (response) => {
          console.log("getWalletDetailsByJoin RES = ", response);
          this.currentBalance = response.data.data.current_balance;
          this.currentPoints = response.data.data.current_points;
          this.students = response.data.data.students;
          console.log("currentPoints = ", this.currentPoints);
        }
      );
    },
  },

  mounted() {
    this.getDataFromApi();
  },
};
</script>

<style></style>
