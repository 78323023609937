<template>
  <v-col cols="12" md="7">
    <h3 class="cart-title mb-5">Cart items</h3>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-if="orderItems.length > 0"
          :headers="table.headersData"
          :items="orderItems"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
          hide-default-header
        >
          <template v-slot:[`item.quantity`]="{ item }">
            <div class="wrapper">
              <v-text-field
                id="quantity"
                class="quantity"
                v-model.number="item.quantity"
                append-outer-icon="add"
                @click:append-outer="increase(item)"
                prepend-icon="remove"
                @click:prepend="decrease(item)"
                @keyup="addingQuantity(item)"
                :rules="[
                  moreThanOneItem(item.quantity),
                  lessThanMaxQuantity(item.quantity, item.max_quantiy),
                  onlyNumbers(item.quantity),
                ]"
              >
              </v-text-field>
            </div>
          </template>
          <!-- eslint-disable-next-line vue/no-unused-vars -->
          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
              meduiem
              @click="deleteItem(index)"
              class="main-color"
              :title="$t('Add New')"
              >delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-form ref="modalForm" v-model="valid">
      <v-row>
        <v-col cols="10" style="position: relative">
          <v-text-field
            v-model="payload.keyword"
            label="Enter Item name"
            prepend-inner-icon="mdi-magnify"
            append-inner-icon="mdi-magnify"
            variant="outlined"
            solo
            class="searchInput"
            @keyup="Search()"
            @keydown.enter.prevent="Search(event, true)"
          ></v-text-field>
          <div class="error-div">
            {{ validation_errors.message || message }}
          </div>
        </v-col>
      </v-row>

      <v-row
        class="list-items"
        v-for="(product, i) in searched_products"
        :key="i"
      >
        <v-col cols="10">
          <v-row>
            <p class="list-name" style="font-weight: 500">
              {{ product.name }}
            </p>
          </v-row>
          <v-row>
            <p class="list-code" style="font-weight: 250">
              {{ product.code }}
            </p>
          </v-row>
        </v-col>
        <v-col
          cols="2"
          v-if="product.is_added"
          @click="addItem(product, i)"
          style="cursor: pointer"
        >
          <div style="margin-bottom: 10px">
            <i class="fa fa-plus main-color" aria-hidden="true"></i>

            <span class="main-color">&nbsp; Add Item</span>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="center">
      <v-dialog v-model="confirm_dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure you want to delete this item ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="alert-cancel-btn"
              text
              @click="confirm_dialog = false"
            >
              No
            </v-btn>
            <v-btn class="alert-save-btn" text @click="confirmDeleteItem">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
export default {
  mixins: [validationMixin],
  data() {
    return {
      allProductPieces: {},
      table: {
        headersData: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
            width: "40%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Code"),
            value: "code",
            width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Price"),
            value: "selling_points",
            width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Quantity"),
            value: "quantity",
            width: "8%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            width: "15%",
            sortable: false,
          },
        ],
      },
      message: "",
      snackbar: false,
      timeout: 4000,
      snack_color: "success",
      confirm_dialog: false,
      loading: false,
      valid: false,
      counter: "",
      validation_errors: {
        quantity: "",
        message: "",
      },
      editedItem: {
        selling_price: "",
        quantity: "",
        product_id: "",
        canteen_id: "",
        confirmed: "",
      },
      delete_index: "",
      orderItems: [],
      searched_products: [],
      payload: {
        keyword: "",
        exclude_products: [],
      },
    };
  },

  methods: {
    Search(event, scanner = false) {
      console.log("event = ", event);
      if (this.valid) {
        HttpService.post("products/search", this.payload, true).then(
          (response) => {
            console.log(
              "response.data.status.message = ",
              response.data.status.message
            );
            if (
              response.data.status.error == false &&
              response.data.data.length >= 1
            ) {
              this.searched_products = response.data.data;
              if (scanner && this.searched_products.length == 1) {
                this.addItem(this.searched_products[0]);
                this.searched_products = [];
              } else if (response.data.data[0] === null) {
                this.message = "Sold Out";
              }
            } else {
              //if no error backend
              // if (this.payload.keyword !== " " && this.payload.keyword !== null)
              //   this.validation_errors.message = "No Matches";
            }
          }
        );
      } else {
        this.$refs.modalForm.validate();
      }
      this.message = "";
      this.searched_products = [];
      this.validation_errors.message = "";
    },

    addItem(product) {
      this.orderItems.push(product);
      this.payload.exclude_products.push(product.id);
      this.payload.keyword = "";
      this.searched_products = []; //to empty searching excepectations list
      this.$emit("sendOrderItems", this.orderItems);
      // this.Search();
    },

    deleteItem(index) {
      this.confirm_dialog = true;
      this.delete_index = index;
    },

    confirmDeleteItem() {
      this.confirm_dialog = false;
      this.orderItems.splice(this.delete_index, 1);
      this.payload.exclude_products.splice(this.delete_index, 1);
      this.$emit("sendOrderItems", this.orderItems);
    },

    increase(item) {
      if (item.quantity < item.max_quantiy) {
        item.disable = false;
        item.quantity += 1;
      } else {
        item.disable = true;
      }
      this.$emit("sendOrderItems", this.orderItems);
    },

    decrease(item) {
      if (item.quantity > 1) {
        item.disable = false;
        item.quantity -= 1;
      } else {
        item.disable = true;
      }
      this.$emit("sendOrderItems", this.orderItems);
    },

    addingQuantity() {
      this.$emit("sendOrderItems", this.orderItems);
    },

    checkValid(item) {
      item.validation_message = "";
      // console.log(item);
      if (item.quantity > item.max_quantiy) {
        item.validation = true;
        item.validation_message =
          "Only " + item.max_quantiy + "  available in stock";
      } else if (item.quantity <= 0) {
        item.validation = true;
        item.validation_message = "Quantity must be more than 0";
      } else if (!/^[0-9]+$/i.test(item.quantity)) {
        item.validation = true;
        item.validation_message = "Please enter only integers numbers";
      }
    },
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    // searched_products() {
    //   return this.searched_products.length >= 1
    //     ? this.searched_products
    //     : "hamada";
    // },
  },

  mounted() {
    // console.log("orderItems = ", this.orderItems);
  },

  updated() {
    // this.passEvent();
  },
};
</script>

<style scoped>
.searchInput {
  border-radius: 30px;
  padding: 10px;
  /* width: 793px;
  height: 51px; */
}

.go-btn {
  font-weight: bold;
  position: absolute;
  right: 32px;
  top: 32px;
  cursor: pointer;
}
.go-btn i {
  font-size: 20px;
  margin-left: -5px;
}

/* Product Quantity */
.wrapper {
  height: 30px;
  display: flex;
  /* width: 80px; */
}
.v-input.quantity {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.v-input.quantity input {
  background-color: white !important;
  height: 28px !important;
  min-height: 28px !important;
  text-align: center !important;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px !important;
  height: 28px !important;
}
.quantity {
  border: none;
  text-align: center;
  width: 50px !important;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
  border: 1px solid #e1e8ee;
  /* padding-bottom: 10px; */
}

.btn {
  border: 1px solid #e1e8ee;
  width: 30px;
  background-color: #e1e8ee;
  cursor: pointer;
  color: #5a86ff;
  font-size: 30px;
}
button:focus,
input:focus {
  outline: 0;
}

.rows-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 83px;

  width: 809px;
  height: 72.01px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.071);
  border-radius: 10px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.cols-items {
  width: 96px;
  height: 20px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #8d8a8a;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.error-msg {
  width: 96px;
  height: 20px;
  padding-bottom: 5px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #ff785a;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.list-items {
  display: flex;
  margin: 5px;
  justify-content: space-between;
  align-items: center;
  width: 793px;
  height: 51px;
  background: #f9f9f9;
}

.list-code {
  padding-bottom: 15px;
  /* padding-top: 15px; */
  padding-left: 15px;
  /* width: 81px; */
  height: 19px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #606060;
  flex: none;
  flex-grow: 0;
}
.list-name {
  padding-top: 15px;
  /* padding-bottom: 15px; */
  padding-left: 15px;
  /* width: 32px; */
  height: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #606060;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.add-item-btn {
  background: #5a86ff;
  border-radius: 4px;
}

.Off {
  color: rgba(114, 151, 255, 0.34);
}

.v-input__icon.v-input__icon--append-outer i {
  color: rgba(114, 151, 255, 0.34);
}

.cart-title {
  width: 809px;
  height: 34px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 34px;
  color: #7297ff;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.error-div {
  color: red;
  margin-top: 0px !important;
  margin-right: 100px !important;
}
</style>
