var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"modalForm",staticClass:"py-4 modal-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Name"))+" ")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.onlyEnglish,
                  ],"solo":""},model:{value:(_vm.editedItem.en.name),callback:function ($$v) {_vm.$set(_vm.editedItem.en, "name", $$v)},expression:"editedItem.en.name"}}),(_vm.validation_errors['en'].name)?_c('div',{staticClass:"validation-div div-validation"},_vm._l((_vm.validation_errors['en'].name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Arabic Name"))+" ")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.CheckArabicCharactersOnly(_vm.editedItem.ar.name),
                  ],"solo":""},model:{value:(_vm.editedItem.ar.name),callback:function ($$v) {_vm.$set(_vm.editedItem.ar, "name", $$v)},expression:"editedItem.ar.name"}}),(_vm.validation_errors['ar'].name)?_c('div',{staticClass:"validation-div div-validation"},_vm._l((_vm.validation_errors['ar'].name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Code"))+" ")]),_c('v-text-field',{attrs:{"rules":[_vm.validationRules.required],"solo":"","disabled":_vm.isPlacedOrder},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}}),(_vm.validation_errors.code)?_c('div',{staticClass:"validation-div div-validation"},_vm._l((_vm.validation_errors.code),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Selling Price"))+" ")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.positiveFloatNumber,
                  ],"solo":""},model:{value:(_vm.editedItem.selling_price),callback:function ($$v) {_vm.$set(_vm.editedItem, "selling_price", $$v)},expression:"editedItem.selling_price"}}),(_vm.validation_errors.selling_price)?_c('div',{staticClass:"validation-div div-validation"},_vm._l((_vm.validation_errors.selling_price),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"value":"val-btn-new"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snack_color,"rounded":"pill","top":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }