<template>
  <div id="navbar">
    <v-card color="grey lighten-4" flat tile>
      <v-toolbar class="toolBar" dense height="65px">
        <!-- <v-app-bar-nav-icon @click="showSideBar"></v-app-bar-nav-icon> -->

        <!-- <v-toolbar-title> Iskola-Canteen </v-toolbar-title> -->

        <v-spacer></v-spacer>

        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="nav-icon" id="notification_icon">
              notifications
            </v-icon>
          </template>
          <v-list id="notification">
            <v-list-item v-for="(item, index) in notifications" :key="index">
              <v-list-item-title class="notification-item" :class="item.class">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>-->
        <!-- notification -->

        <label style="margin: 10px; color: #7296fb"
          ><a @click="ChangeLanguage">{{
            currentAppLocale == "en" || currentAppLocale == undefined
              ? $t("AR")
              : $t("EN")
          }}</a></label
        >
        <span class="ml-2"> | &nbsp;</span>
        <p style="margin-left: 10px; margin-top: 18px" class="username">
          <span class="auth-name">
            {{ auth_name.charAt(0).toUpperCase() + auth_name.slice(1) }}
          </span>
          <br />
          (
          <span
            class="auth-roles"
            style="text-align: left"
            v-for="(role, index) in roles"
            v-bind:key="index"
          >
            <!-- <div v-for="role in roles" v-bind:key="role"></div> -->
            {{ role }} {{ index < roles.length - 1 ? " / " : "" }}
          </span>
          )
        </p>
        <v-menu offset-y style="z-index: 1111 !important; width: 200px">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="nav-icon"
              id="account_circle_icon"
              style="color: #444"
              >arrow_drop_down</v-icon
            >
          </template>

          <v-list id="accountMenu">
            <div v-for="(item, index) in accountitems" :key="index">
              <v-list-item link :to="item.path" v-if="item.enabled">
                <v-list-item-title
                  class="notification-item"
                  :class="item.class"
                  @click="
                    if (item.method == 'logout') logout();
                    else if (item.method == 'switchRole') switchRole();
                    else hide();
                  "
                >
                  <v-icon>{{ item.icon }}</v-icon>
                  <label
                    v-if="
                      item.title != 'last Login :' &&
                      item.title != 'اخر تسجيل للدخول :'
                    "
                    >{{ item.title }}</label
                  >
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <span>
          <img
            id="logo"
            src="../assets/avatar.png"
            style="width: 40px; height: 40px; border-radius: 50%"
          />
        </span>
      </v-toolbar>
    </v-card>

    <!-- user update data modal -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentAppLocale: localStorage.locale,
      accountitems: [
        // {
        //   title: this.$i18n.t("My Profile"),
        //   icon: "person",
        //   method: "",
        //   class: "",
        //   path: "/profile",
        //   enabled: true,
        // },

        {
          title: this.$i18n.t("Logout"),
          icon: "check_circle",
          method: "logout",
          class: "",
          path: "",
          enabled: true,
        },
      ],
      auth_name: localStorage.auth_name,
      roles: JSON.parse(localStorage.selectedRole),
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      window.location = "/login";
    },
    ChangeLanguage() {
      //
    },
  },
};
</script>

<style>
.auth-roles {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.auth-name {
  width: 92px;
  height: 23px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: #000000;

  transform: rotate(-0.03deg);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
