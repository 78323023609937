<template>
  <!-- PARENT -->
  <v-container>
    <v-row>
      <addOrder v-on:sendOrderItems="calculateAmount($event)"></addOrder>
      <EmptySpace></EmptySpace>
      <PaymentComponent :allAmount="allAmount" :orderDetails="orderDetails">
      </PaymentComponent>
    </v-row>
  </v-container>
</template>

<script>
import addOrder from "../components/AddOrder.vue";
import EmptySpace from "../components/EmptySpace.vue";
import PaymentComponent from "../components/PaymentComponent.vue";
export default {
  name: "Orders",
  components: {
    addOrder,
    PaymentComponent,
    EmptySpace,
  },
  data() {
    return {
      allAmount: 0,
      orderDetails: [],
    };
  },
  methods: {
    calculateAmount(event) {
      // console.log("event = ", event);
      //changing allAmount in price to allAmount in points
      this.allAmount = 0;
      this.orderDetails = [];
      var index = 0;
      event.forEach((value) => {
        if (Number.isInteger(value.quantity)) {
          this.allAmount +=
            parseFloat(value.quantity) * parseFloat(value.selling_points);

          this.orderDetails[index] = value;
          index++;
        }
      });
      this.allAmount = Number(this.allAmount).toFixed(2);
      // console.log(this.orderDetails);
      // console.log(!!"false" === !!false);
    },
  },
};
</script>

<style>
/* .disregard-btn {
  text-transform: capitalize;
  position: fixed;
  right: 55px;
  bottom: 100px;
  padding: 5px;
} */
</style>
