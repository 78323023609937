<template>
  <!--  It Acts like an Orderhistory & Wallet for the Authanticted Student -->
  <!--  It Acts like an Orderhistory for the Childern of Authanticted Parent -->
  <v-container style="padding-left: 0px">
    <v-row>
      <v-col cols="6" style="position: relative">
        <!-- this table for viewing to the Parent & Student -->
        <DataTableComponent
          :table="tableData"
          :children="students"
          :selectedStudentID="selected_child_id"
          v-on:studentID="getStudentID($event)"
        ></DataTableComponent>
      </v-col>
      <EmptySpace></EmptySpace>
      <MyWallet
        v-if="!isParent"
        :studentID="auth_id"
        :currentBalance="currentBalance"
        :activateRecharge="activateRecharge"
      ></MyWallet>
    </v-row>
  </v-container>
</template>

<script>
import MyWallet from "../components/MyWallet.vue";
import EmptySpace from "../components/EmptySpace.vue";
import DataTableComponent from "../components/DataTableComponent.vue";
import HttpService from "../services/HttpService.js";

export default {
  components: {
    MyWallet,
    DataTableComponent,
    EmptySpace,
  },
  props: ["id"],

  data() {
    return {
      isParent: localStorage.getItem("selectedRole").includes("Parent")
        ? true
        : false,
      students: [],
      activateRecharge: false,
      currentBalance: 0,
      array_of_props_id: [],
      auth_id: -1,
      selected_child_id: -1,
      tableData: {
        title: "Order",
        model: localStorage.getItem("selectedRole").includes("Parent")
          ? "history"
          : "",
        headersData: [
          {
            text: this.$i18n.t("Order ID"),
            value: "order_number",
            align: "center",
            width: "33%",
          },
          {
            text: this.$i18n.t("Amount"),
            value: "total",
            sortable: false,
            align: "center",
            width: "33%",
          },
          {
            text: this.$i18n.t("Date & Time"),
            value: "updated_at",
            sortable: false,
            align: "center",
            width: "33%",
          },
        ],
        endpoints: {
          list: "", //كده بعت اى_دى الابن
          calender: "filter",
          children: "children",
        },
      },
      orderDetails: {},
    };
  },
  methods: {
    getChildren() {
      if (localStorage.getItem("selectedRole").includes("Student") == true) {
        //معنديش ابناء للطالب ف هخلي الابناء هو الطالب اللي عامل لوجن نفسه
        this.students[0] = {};
        this.students[0] = Object.assign(
          {},
          {
            name: localStorage.getItem("auth_name").split(" ")[0],
            id: localStorage.getItem("auth_id"),
          }
        );
      } else {
        //هنا عندى ابناء لاب اللي عامل لوجن فهبدا اجيبهم من الباك اند
        HttpService.get("wallet/myChildren", "", true).then((response) => {
          if (response.data.data.length > 0) {
            this.students = response.data.data;
            console.log("studentsOrders = ", this.students);
          }
        });
      }
    },

    showID() {
      //if i clicked on withdrowal link => should select the student ID
      this.array_of_props_id = this.id.split(",");
      this.auth_id = this.array_of_props_id[0];
      this.selected_child_id = this.array_of_props_id[1];
      this.tableData.endpoints.list =
        "wallet/Studentwallet/orders/" + this.selected_child_id;
    },
  },

  created() {
    this.getChildren();
    this.showID();
  },

  mounted() {
    // this.getChildren();
  },
};
</script>

<style></style>
