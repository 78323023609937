<template>
  <v-container>
    <v-row class="">
      <v-col cols="12">
        <DataTableComponent
          ref="dataTableComponent"
          v-on:deactivatedItem="deactivateThisItem($event)"
          :table="tableData"
        ></DataTableComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableComponent from "../components/DataTableComponent.vue";
import HttpService from "../services/HttpService.js";
export default {
  name: "Canteen",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      tableData: {
        parent: "canteen-view",
        title: "Canteen",
        headersData: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
            width: "15%",
          },
          {
            text: this.$i18n.t("Code"),
            value: "code",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$i18n.t("Warehouse-Member"),
            value: "warehouse_name",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$i18n.t("Canteen-Staff"),
            value: "canteen_staff_name",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        endpoints: {
          list: "canteens",
          create: "canteens",
          update: "canteens",
          // delete: "canteens",
          deactivate: "yes",
        },
        activation: {
          isActive:
            "Turn off to stop future orders as well as future quantities for this canteen",
          isInactive:
            "Turn on to allow future orders as well as future quantities for this canteen",
        },
      },
    };
  },
  methods: {
    deactivateThisItem(event) {
      console.log(event);
      HttpService.post("canteens/deActivateCanteen/" + event.id, {}, true).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          console.log("deActivateCanteen = ", response.data.data.deactivate);
        }
      );
    },
  },
};
</script>

<style></style>
