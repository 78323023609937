<template>
  <v-container>
    <v-breadcrumbs
      :items="breadcrumbs_items"
      style="padding-left: 10px !important"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          @click="
            isParent
              ? $router.push(
                  '/OrdersHistory/' + auth_id + ',' + selectedStudentID
                )
              : $router.push('/OrdersHistory/' + 7)
          "
          :disabled="item.disabled"
        >
          {{ item.title }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- -------------Breadcrumbs------------------- -->

    <v-row class="headerTable-div2" style="width: 50%">
      <v-col md="4" sm="12">
        <h3 class="custom-header main-color">{{ title }}</h3>
      </v-col>
      <v-col md="5" sm="12"> </v-col>
      <v-col md="3" sm="12">
        <h6
          style="
            width: 156px;
            height: 16px;
            color: #ababab;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            padding-top: 15px;
          "
        >
          {{ created_at }}
        </h6>
      </v-col>
    </v-row>
    <v-btn
      rounded
      color="rgba(90, 134, 255, 0.3)"
      dark
      style="
        color: #5a86ff;
        margin: 10px;
        margin-left: 0px;
        text-transform: capitalize;
        width: auto;
      "
    >
      {{ studentName }}
    </v-btn>
    <v-row>
      <v-col cols="6" style="position: relative">
        <v-data-table
          :headers="table.headersData"
          :items="orderItems"
          class="elevation-1 orderTable"
          item-key="id"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
      <EmptySpace></EmptySpace>
    </v-row>
  </v-container>
</template>

<script>
import EmptySpace from "../components/EmptySpace.vue";
import HttpService from "../services/HttpService.js";

export default {
  components: {
    EmptySpace,
  },
  props: ["order"],

  data() {
    return {
      isParent: localStorage.getItem("selectedRole").includes("Parent"),
      auth_id: localStorage.getItem("auth_id"),
      selectedStudentID: 19,
      students: 0,
      currentBalance: 0,
      created_at: 0,
      title: "Order Details",
      orderItems: [],
      studentName: "",
      order_id: -1,
      table: {
        headersData: [
          {
            text: this.$i18n.t("Product"),
            value: "product_id.name",
            // width: "40%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Points"),
            value: "price",
            // width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Quantity"),
            value: "quantity",
            // width: "15%",
            sortable: false,
          },
          {
            text: this.$i18n.t("Total"),
            value: "order_id.total",
            // width: "8%",
            sortable: false,
          },
        ],
      },
      breadcrumbs_items: [
        {
          title: "Orders",
          disabled: false,
        },
        {
          title: "",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    callAPI() {
      console.log("order pro= ", this.order);
      var splitStringArray = this.order.split(",");
      this.studentName = splitStringArray[1];
      this.order_id = splitStringArray[0];
      this.breadcrumbs_items[1].title = splitStringArray[2];

      HttpService.get(
        "wallet/Studentwallet/getOrderDetailes/" + this.order_id,
        "",
        true
      ).then((response) => {
        this.orderDetails = response.data.data;
        this.created_at = this.orderDetails[0].created_at;
        console.log("orderDetails", this.orderDetails);
        for (var i = 0; i < this.orderDetails.length; i++) {
          this.orderItems.push(this.orderDetails[i]);
          this.orderItems[i].order_id.total =
            this.orderItems[i].quantity * this.orderItems[i].price + " pts";
          this.orderDetails[i].price =
            Math.round(this.orderDetails[i].price) + " pts";
        }
      });
      console.log("orderItems", this.orderItems);
    },

    hasHistory() {
      return window.history.length > 2;
    },
  },

  mounted() {
    this.callAPI();
  },
};
</script>

<style>
.headerTable-div2 {
  /* border-top: 1px solid #ddd; */
  border-bottom: 1px solid #7297ff;
  background-color: #fff;
  margin-bottom: 10px;
}
.headerTable-div2 .custom-header {
  margin: 0 10px;
  width: 199.98px;
  height: 34px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 34px;
  color: #5a86ff;
  flex: none;
  order: 0;
  flex-grow: 1;
}
</style>
