import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/index.js";
import axios from "axios";
import VueI18n from "vue-i18n";
import ar from "./translations/ar.json";
import en from "./translations/en.json";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/css/all.min.css"; // Fontawesome CSS
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    ar: ar,
    en: en,
  },
  silentTranslationWarn: true,
});

import "./custom-style.css";

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
