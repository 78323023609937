<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container>
    <v-dialog v-model="addEditDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(formTitle) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="modalForm" class="py-4 modal-form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Name") }} </label>
                  <v-text-field
                    v-model="editedItem.en.name"
                    :rules="[
                      validationRules.required,
                      validationRules.onlyEnglish,
                    ]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors['en'].name"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors['en'].name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------------------- -->
              <!-- ----------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Arabic Name") }} </label>
                  <v-text-field
                    v-model="editedItem.ar.name"
                    :rules="[
                      validationRules.required,
                      CheckArabicCharactersOnly(editedItem.ar.name),
                    ]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors['ar'].name"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors['ar'].name"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Code") }} </label>
                  <v-text-field
                    v-model="editedItem.code"
                    :rules="[validationRules.required]"
                    solo
                    :disabled="isPlacedOrder"
                  ></v-text-field>
                  <div
                    v-if="validation_errors.code"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.code"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- -------------------------------------------------- -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label>{{ $t("Selling Price") }} </label>
                  <v-text-field
                    v-model="editedItem.selling_price"
                    :rules="[
                      validationRules.required,
                      validationRules.positiveFloatNumber,
                    ]"
                    solo
                  ></v-text-field>
                  <div
                    v-if="validation_errors.selling_price"
                    class="validation-div div-validation"
                  >
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.selling_price"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- ---------------------------------------------------------- -->
            </v-form>
          </v-container>
        </v-card-text>
        <!-- ---------------------Buttons Save & Cancel--------------- -->
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            @click="save"
            :loading="loading"
            :disabled="loading"
            value="val-btn-new"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- --------------snackBar------------------------------ -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="snack_color"
      rounded="pill"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ---------------------Confirmation Dialog Message--------------------- -->
  </v-container>
</template>

<script>
import { validationMixin } from "../mixins/validation-mixin";
import HttpService from "../services/HttpService.js";
export default {
  mixins: [validationMixin],
  props: ["addEditDialog", "editedIndex"],
  data: () => ({
    message: "",
    snackbar: false,
    timeout: 4000,
    snack_color: "success",
    confirm_dialog: false,
    loading: false,
    valid: false,
    isPlacedOrder: false,
    editedItem: {
      ar: {
        name: "",
      },
      en: {
        name: "",
      },
      code: "",
      selling_price: "",
    },
    defaultItem: {
      ar: {
        name: "",
      },
      en: {
        name: "",
      },
      code: "",
      selling_price: "",
    },
    validation_errors: {
      ar: {
        name: "",
      },
      en: {
        name: "",
      },
      code: "",
      selling_price: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Product Item"
        : "Edit Product Item Data";
    },
  },

  methods: {
    setEditedItem(item) {
      HttpService.get("products/", item.id, true).then((response) => {
        this.isPlacedOrder = response.data;
        console.log("isPlacedOrder = ", this.isPlacedOrder);
      });
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, item);
      });
      this.validation_errors = Object.assign(
        {},
        {
          ar: {
            name: "",
          },
          en: {
            name: "",
          },
          code: "",
          selling_price: "",
        }
      );
    },
    onFocus() {
      this.$refs.modalForm.resetValidation();
    },

    close() {
      // Child Shouldn't change parent property which is passed as props
      // this.addEditDialog = false;
      // Instead, It should call an event to call a function in parent to change the proerty to false
      this.editedItem = {
        ar: {
          name: "",
        },
        en: {
          name: "",
        },
        code: "",
        selling_price: "",
      };
      this.$emit("closeDialog");
      this.$nextTick(() => {});
      this.$refs.modalForm.resetValidation();
      this.validation_errors = Object.assign(
        {},
        {
          ar: {
            name: "",
          },
          en: {
            name: "",
          },
          code: "",
          selling_price: "",
        }
      );
      this.confirm_dialog = false;
    },

    save() {
      if (this.editedIndex > -1) {
        // edit mode
        if (this.valid) {
          this.update();
        } else {
          this.$refs.modalForm.validate();
        }
      } else {
        // create mode
        this.store();
      }
    },

    store() {
      if (this.valid) {
        this.loading = true;
        HttpService.post("products", this.editedItem, true).then((response) => {
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.message = "Product Item Data has already Added";
            this.snack_color = "success";
            this.editedItem = Object.assign({}, this.defaultItem);
            this.snackbar = true;
            this.loading = false;
            this.close();
          } else {
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              // this.validation_errors = response.data.status.validation_errors;
              console.log(response.data.status.validation_errors);
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
              console.log(this.validation_errors.en.name);
            } else {
              // if there's an error but it's not validation error
              this.snack_color = "error";
              this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },

    update() {
      if (this.valid) {
        this.loading = true;
        console.log(" this.editedIndex = ", this.editedIndex);
        HttpService.post(
          "products/edit/" + this.editedIndex,
          this.editedItem,
          true
        ).then((response) => {
          console.log(this.editedItem);
          console.log("respone", response);
          this.message = response.data.status.message;
          if (response.data.status.error == false) {
            this.message = "Product Item Data has already Edited";
            this.snack_color = "success";
            this.editedItem = Object.assign({}, this.defaultItem);
            this.snackbar = true;
            this.loading = false;
            this.close();
          } else {
            this.loading = false;
            // if there's an error
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              // if there's validation errors from backend
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              // if there's an error but it's not validation error
              this.snack_color = "error";
              this.snackbar = true;
            }
          }
          this.$emit("getData");
        });
      } else {
        this.$refs.modalForm.validate();
      }
    },
  },

  created() {},
};
</script>
<style></style>
