import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

const HttpService = {
  async get(endpoint, params = "", withAuth = false) {
    let headers = {
      "Content-Type": "application/json",
      locale: localStorage.locale !== undefined ? localStorage.locale : "en",
    };
    if (withAuth) {
      headers.Authorization = "Bearer " + localStorage.token;
    }
    if (params != "") {
      endpoint = endpoint + params;
    }
    return await axios
      .get(`${API_URL}/${endpoint}`, { headers: headers })
      .then((response) => {
        return response;
      });
  },

  async post(endpoint, body = {}, withAuth = false) {
    let headers = {
      "Content-Type": "application/json",
      locale: localStorage.locale !== undefined ? localStorage.locale : "en",
    };
    if (withAuth) {
      headers.Authorization = "Bearer " + localStorage.token;
    }
    return await axios
      .post(`${API_URL}/${endpoint}`, body, { headers: headers })
      .then((response) => {
        return response;
      });
  },

  async delete(endpoint, withAuth = false) {
    let headers = {
      "Content-Type": "application/json",
      locale: localStorage.locale !== undefined ? localStorage.locale : "en",
    };
    if (withAuth) {
      headers.Authorization = "Bearer " + localStorage.token;
    }
    return await axios
      .delete(`${API_URL}/${endpoint}`, { headers: headers })
      .then((response) => {
        return response;
      });
  },
};

export default HttpService;
