export const validationMixin = {
  data() {
    return {
      validationRules: {
        required: (v) => {
          return !!v || "This field is required";
        },
        validEmail: (v) => {
          if (v) {
            return /.+@.+\..+/.test(v) || "Valid email xxxxx@example.com";
          } else {
            return true;
          }
        },

        validFormEmail: (v) => {
          if (v) {
            return /.+@.+\..+/.test(v) || "Invalid E-mail";
          } else {
            return true;
          }
        },

        validPassword: (v) => {
          if (v) {
            return v.length >= 8 || "Password must be 8 characters at least";
          } else {
            return true;
          }
        },

        onlyArabic: (v) => {
          if (v) {
            return (
              /0-9\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd50-\ufd8f\ufe70-\ufefc\uFDF0-\uFDFD/.test(
                v
              ) || "من فضلك ادخل حروف عربية "
            );
          } else {
            return true;
          }
        },

        onlyEnglish: (v) => {
          if (v) {
            return (
              /^[a-z][a-z\s]*$/i.test(v) || "Please enter only English letters"
            );
          } else {
            return true;
          }
        },

        onlyLetters: (v) => {
          if (v) {
            return (
              /0-9\u0600-\u06ff\u0750-\u077f\ufb50-\ufbc1\ufbd3-\ufd3f\ufd50-\ufd8f\ufd50-\ufd8f\ufe70-\ufefc\uFDF0-\uFDFD/.test(
                v
              ) ||
              /^[a-z][a-z\s]*$/i.test(v) ||
              "Please write only letters"
            );
          } else {
            return true;
          }
        },

        onlyِArabicOrEnglishLetters: (v) => {
          if (v) {
            return (
              /^(?:(?=[\p{Script=Arabic}A-Za-z])\p{L}|\s)+$/u.test(v) ||
              "Only Arabic or English letters"
            );
          } else {
            return true;
          }
        },

        maxLength25: (v) => {
          if (v) {
            return (
              v.length <= 25 || "Length must not be greater than 25 character"
            );
          } else {
            return true;
          }
        },

        minLength25: (v) => {
          if (v) {
            return v.length >= 25 || "Length must be at least 25 character";
          } else {
            return true;
          }
        },

        onlyIntegers: (v) => {
          if (v) {
            return /^[0-9]+$/i.test(v) || "Numbers only ";
          } else {
            return true;
          }
        },

        onlyNumbersAndDashes: (v) => {
          if (v) {
            return /^[0-9-]+$/i.test(v) || "Numbers and Dashes only ";
          } else {
            return true;
          }
        },

        countedNumbers: (v) => {
          if (v) {
            return /^[1-9]+$/i.test(v) || "Please enter only numbers";
          } else {
            return true;
          }
        },

        positiveFloatNumber: (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) > 0 && /^\d+(\.\d{1,2})?$/.test(v)) ||
              this.$i18n.t("allows decimals, up to 2 digits ")
            );
          } else return true;
        },
      },
    };
  },

  methods: {
    CheckArabicCharactersOnly(val) {
      if (val != null && val != undefined && val != "") {
        let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

        for (var i = 0; i < val.length; i++) {
          // console.log(val.charAt(i));
          var unicode = val.charCodeAt(i);
          var error = "";
          if (unicode != 8 && !format.test(val[i])) {
            //if the key isn't the backspace key (which we should allow)
            if (unicode == 32) error = true;
            else {
              if (
                (unicode < 48 || unicode > 57) &&
                (unicode < 0x0600 || unicode > 0x06ff)
              ) {
                //if not a number or arabic
                error = "Please write only arabic letters.";
                return error;
              } else {
                error = true; //disable key press
              }
            }
          }
        }
        return error;
      }
      return true;
    },

    lessThanMaxQuantity(quantity, max_quantity) {
      var error = "";
      // console.log(quantity);
      // console.log(max_quantity);
      if (quantity > max_quantity || quantity < 1) {
        error = "Only " + max_quantity + "  available in stock";
      } else {
        error = true;
      }
      return error;
    },

    moreThanOneItem(quantity) {
      var error = "";
      // console.log(quantity);
      // console.log(max_quantity);
      if (quantity <= 0) {
        error = "Quantity must be more than 0";
      } else {
        error = true;
      }
      return error;
    },

    onlyNumbers(quantity) {
      var error = "";
      if (!/^[0-9]+$/i.test(quantity)) {
        error = "Please enter only numbers";
      } else {
        error = true;
      }
      return error;
    },

    // codeNotFound() {
    //   // var error;
    // },
  },
};
