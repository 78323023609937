<template>
  <div id="login-page" class="login-page">
    <v-row style="height: 100vh">
      <v-col class="side-page d-none d-md-block" cols="12" md="6" sm="12">
        <svg
          version="1.1"
          id="e7b3b0c3-1e4f-480c-906c-c03021e90d10"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 991.1 770.2"
          style="enable-background: new 0 0 991.1 770.2"
          xml:space="preserve"
        >
          <title>{{ $t("professor") }}</title>
          <path
            class="st0"
            d="M289.6,0L131.7,85.5c11.1,19.2,4.6,43.8-14.6,54.9C98,151.5,73.4,145,62.3,125.8c-0.4-0.7-0.8-1.4-1.1-2.1
	L7.3,152.9l139.4,257.4L429,257.4L289.6,0z"
          />
          <circle class="st1" cx="94.6" cy="105.7" r="32.6" />
          <path
            class="st0"
            d="M686,689.1l170.7-55.7c-7.5-20.9,3.3-43.9,24.2-51.4s43.9,3.3,51.4,24.2c0.3,0.8,0.5,1.5,0.7,2.3l58.2-19
	l-90.8-278.3l-305.2,99.5L686,689.1z"
          />
          <circle class="st1" cx="896.8" cy="620.2" r="32.6" />
          <rect x="115.5" y="152.7" class="st2" width="788" height="358" />
          <rect x="128.5" y="165.7" class="st3" width="762" height="325" />
          <rect x="739" y="475.2" class="st2" width="74" height="25" />
          <rect x="681" y="494.2" class="st1" width="54" height="7" />
          <polyline
            class="st4"
            points="190.5,288.7 481.5,288.7 526.6,310.7 598.5,345.7 645.5,257.7 778.5,257.7 "
          />
          <polyline class="st4" points="304.9,288.7 326.5,339.7 379.5,339.7 " />
          <polyline class="st4" points="385.8,288.7 459.5,248.7 511.5,248.7 " />
          <circle class="st1" cx="304.5" cy="288.7" r="9" />
          <circle class="st1" cx="388.5" cy="288.7" r="9" />
          <polyline class="st4" points="526.5,310.7 532.5,399.7 584.5,399.7 " />
          <polyline class="st4" points="626.7,293 569.5,248.7 623.5,248.7 " />
          <polyline class="st4" points="721.1,257.7 776.5,301.7 828.5,301.7 " />
          <circle class="st1" cx="526.5" cy="310.7" r="9" />
          <circle class="st1" cx="720.5" cy="257.7" r="9" />
          <circle class="st1" cx="626.5" cy="292.7" r="9" />
          <line class="st4" x1="327.5" y1="347.7" x2="379.5" y2="347.7" />
          <path class="st4" d="M511.5,257.7L511.5,257.7z" />
          <path class="st4" d="M623.5,256.7L623.5,256.7z" />
          <path class="st4" d="M623.5,264.7L623.5,264.7z" />
          <path class="st4" d="M828.5,309.7L828.5,309.7z" />
          <path class="st4" d="M809.5,317.7L809.5,317.7z" />
          <path class="st4" d="M584.5,407.7L584.5,407.7z" />
          <circle class="st4" cx="809.5" cy="75.5" r="14.8" />
          <ellipse class="st0" cx="152" cy="742.2" rx="152" ry="28" />
          <polygon
            class="st1"
            points="256.4,493.7 229.3,507.3 231,510.8 258.1,497.2 "
          />
          <path
            class="st5"
            d="M252,468.2l-1.2,7.3c0,0,1.8,50.6-11.6,34.7s-4.3-37.2-4.3-37.2l3.7-6.1L252,468.2z"
          />
          <path
            class="st5"
            d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
          />
          <path
            class="st6"
            d="M174,234.3c0,0-2.4,25.6-3.7,26.8s18.3,24.4,18.3,24.4l17.1-15.8c0,0-1.2-23.1,2.4-28S174,234.3,174,234.3z"
          />
          <polygon
            class="st2"
            points="128.9,700.9 128.9,722.8 120.4,724 108.2,720.4 111.9,697.2 "
          />
          <path
            class="st7"
            d="M118,711.9c0,0-3.7-9.7-9.7-8.5s-7.3,8.5-7.3,8.5s-4.9,18.3-12.2,24.4s-14.6,21.9,0,24.4s26.8-3.7,26.8-4.9
	s3.7-8.5,9.7-9.7c6.1-1.2,11-2.4,11-7.3s-3.7-9.7-3.7-9.7s-1.2-23.1-3.7-20.1c-1.7,2.4-2.9,5.1-3.7,7.9L118,711.9z"
          />
          <polygon
            class="st2"
            points="181.3,689.9 176.4,708.2 188.6,710.6 194.7,704.5 195.9,688.7 "
          />
          <path
            class="st7"
            d="M180.1,705.8c0,0,4.9-3.7,7.3-3.7h3.7c0,0,0-8.5,6.1-8.5s4.9,6.1,4.9,6.1s13.4,20.7,17.1,24.4
	s23.1,14.6,7.3,19.5s-26.8,4.9-34.1-3.7c0,0-7.3-3.7-12.2-3.7s-11-2.4-11-6.1s3.7-11,3.7-11s2.4-21.9,3.7-19.5
	S180.1,705.8,180.1,705.8z"
          />
          <path
            class="st7"
            d="M127.7,409.7v40.2c0,0-13.4,78-13.4,103.5s-20.7,147.4-11,149.8s32.9,6.1,34.1,2.4s9.7-119.4,11-120.6
	c1.2-1.2,26.8-97.5,26.8-97.5l4.9,1.2l2.4,109.6c0,0-12.2,98.7-6.1,98.7s31.7-1.2,31.7-8.5s13.4-142.5,13.4-142.5
	S237.4,439,231.3,428s-6.1-17.1-6.1-17.1L127.7,409.7z"
          />
          <circle class="st5" cx="194.1" cy="228.8" r="24.4" />
          <path
            class="st8"
            d="M188.6,270.9c0,0-15.2-12.8-16.4-17.7c0,0-7.9,7.9-7.9,9.1s-34.1,4.9-42.6,14.6s-15.8,30.5-15.8,30.5l20.7,39
	c0,0,1.2,21.9,1.2,24.4s-6.1,41.4-3.7,42.6s26.8,13.4,47.5,11s41.4-6.1,47.5-6.1s11,2.4,11,0s-3.7-7.3-3.7-9.7s-1.2-8.5-1.2-12.2
	s-2.4-9.7-2.4-14.6s26.8-70.7,26.8-70.7s-1.2-20.7-20.7-30.5s-21.9-13.4-21.9-13.4s-0.4-7.3-1.4-7.3s-4.7,11-14.4,12.2L188.6,270.9z
	"
          />
          <polygon
            class="st5"
            points="122.8,432.9 131.4,446.3 153.3,441.4 137.5,423.1 "
          />
          <path
            class="st8"
            d="M242.2,301.3l7.3,9.7c0,0,14.6,70.7,12.2,96.2s-4.9,45.1-4.9,45.1s4.9,18.3,1.2,18.3s-25.6,1.2-25.6-2.4
	s2.4-26.8,2.4-26.8l-6.1-53.6l-13.4-18.3L242.2,301.3z"
          />
          <path
            class="st7"
            d="M177.6,217c1.5-0.6,3.2-0.1,4.8-0.4c1.9-0.3,3.6-1.7,5.5-1.5c0.9,0.2,1.8,0.5,2.6,0.8c0.8,0.4,1.8,0.4,2.7,0.1
	c0.8-0.4,1.1-1.3,0.7-2c0-0.1-0.1-0.2-0.2-0.3c1.6-0.1,3.1-0.8,4.3-1.9c0.2-0.2,0.5-0.5,0.8-0.6c0.7-0.2,1.5-0.1,2.1,0.3l2.8,1.3
	c0.6,0.2,1.2,0.6,1.6,1.1c0.3,0.5,0.4,1.2,0.9,1.6c0.6,0.5,1.5,0.3,2.3,0.3c1.5,0.1,2.6,1.4,2.6,2.8c0,0.8-0.3,1.8,0.3,2.3
	c0.4,0.3,0.9,0.2,1.3,0.4c1.1,0.5,1.1,2,1,3.3s-0.1,2.8,1.1,3.2c0.8,0.3,1.7-0.3,2.5-0.4c1.3-0.2,2.5,0.6,3.6,1.2s2.7,1,3.7,0.2
	c0.9-0.8,0.9-2.2,0.6-3.4c-0.4-1.7-1-3.3-1.8-4.9c-0.3-0.5-0.5-1-0.5-1.5c0.1-0.8,0.6-1.5,1.3-1.9c1.4-1.1,3-2.2,3.5-4
	c0.3-1.3,0-2.6,0-3.9c0-1.6,0.7-3.2,0.4-4.8c-0.5-2.9-3.7-4.2-5.8-6.3c-2.8-2.8-3.7-7.4-7.1-9.6c-2.7-1.8-6.1-1.6-9.2-2.6
	c-3.3-1.1-6-3.7-9.3-4.9c-3.6-1.3-7.7-1-11,1c-1,0.7-2.1,1.3-3.2,1.7c-1,0.1-2,0.1-2.9,0c-1.9-0.1-3.9,0.3-5.6,1.2
	c-0.7,0.3-1.2,0.8-1.6,1.4c-0.6,1.3,0.3,3.4-1,4c-0.6,0.3-1.3,0-2,0c-1.3,0-2.3,1.2-2.9,2.3c-0.5,1.2-1.2,2.3-2,3.3
	c-1.6,1.4-4.5,1.6-4.9,3.7c0,0.9,0,1.8,0.1,2.6c-0.1,1.8-2.1,3.2-2,5.1c0.1,2.1,2.8,3.2,3.5,5.1c0.5,1.5-0.2,3.2-0.7,4.7
	s-0.6,3.5,0.6,4.5c0.5,0.4,1.1,0.5,1.7,0.9c1.3,1,1.3,2.9,1.2,4.5c-0.2,0.7,0,1.4,0.5,1.9c0.4,0.3,1,0.3,1.5,0.2
	c1.6-0.4,3-1.4,3.8-2.9c0.4-0.7,0.5-1.3,1.3-1.7c0.5-0.3,1.2,0,1.7-0.4C175.3,222.8,174.4,218.3,177.6,217z"
          />
          <path
            class="st8"
            d="M109.4,303.7l-3.7,3.7c0,0-21.9,65.8-14.6,80.4s21.9,51.2,26.8,49.9s21.9-12.2,21.9-13.4S119.2,389,119.2,389
	s-2.4-7.3-1.2-8.5s1.2,0,0-2.4s-1.2,0,1.2-2.4s2.4-2.4,2.4-3.7s7.3-31.7,7.3-31.7L109.4,303.7z"
          />
          <polygon
            class="st7"
            points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
          />
          <polygon
            class="st6"
            points="128.3,446.9 155.1,438.4 158.8,446.9 129.5,453 "
          />
          <polygon class="st6" points="164.9,262.9 185.6,280 188,276.3 " />
          <polygon class="st6" points="206.3,267.8 191.7,276.3 192.9,280 " />
        </svg>
      </v-col>
      <v-col class="mb-4" cols="6">
        <LoginForm />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  data() {
    return {
      //
    };
  },
  components: {
    LoginForm,
  },
};
</script>

<style lang="scss">
/* ======================== start login and admission Form ========================*/
.displayFormSection {
  display: block !important;
}
.form-header {
  font-size: 30px;
  font-weight: 700;
}
#login-page,
#admission-page {
  margin: 0 auto;

  .side-page {
    background-color: #7297ff;
  }

  .form-page {
    width: 100%;
    padding: 35px;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 17px #d1d1d1 !important;
    box-shadow: 0px 3px 17px #d1d1d1 !important;
    margin: 0 auto;
  }
  @media (max-width: 600px) {
    .form-page {
      padding: 16px;
    }
  }
  #page1,
  #page2,
  #page3 {
    transition: all 0.4s ease 0s;
    display: none;
  }
}

h3 {
  color: #7297ff;
}

.progress-btns {
  height: 15px;
  width: 15px;
  border: 1px solid #7297ff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;

  margin: 8px 16px;
}

.previous {
  color: #7297ff;
  height: 25px;
}
.next {
  color: #7297ff;
}

.form-control {
  height: 40px;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #111111;
  border-radius: 4px;
  border: none;
  padding: 5px 15px;
}

.form-control,
.custom-select {
  box-shadow: 0px 2px 2px #d1d1d1;
  border: 0;
  background-color: #ffffff;
  margin-bottom: 10px;
}

input[type="text"]:focus,
textarea:focus,
.custom-select:focus,
.form-control:focus {
  box-shadow: 0 0 5px rgba(114, 151, 255, 0.5);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(114, 151, 255, 0.5);
}

.custom-file {
  width: 55%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  margin-left: 25px;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      display: block;
      padding: 7px 2;
      background-color: unset !important;
      color: #7297ff;
    }
  }
}

form {
  h3 {
    padding: 0px;
    // line-height: 0.4;
  }
  h4 {
    color: #7297ff;
  }
}

.st0 {
  fill: #f2f2f2;
}
.st1 {
  fill: #6c63ff;
}
.st2 {
  fill: #3f3d56;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  fill: none;
  stroke: #3f3d56;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st5 {
  fill: #fbbebe;
}
.st6 {
  opacity: 0.1;
  enable-background: new;
}
.st7 {
  fill: #2f2e41;
}
.st8 {
  fill: #d0cde1;
}

.radio-group {
  margin-left: 0;
  &::selection {
    background-color: #fff;
  }
}
#login-form label {
  margin-bottom: 0;
  margin-top: 15px;
}

select:-internal-list-box option:checked {
  background-color: #fff;
}
.btn-primary {
  margin-top: 10px;
  background-color: #7297ff;
}

#upload-photo .custom-file-label {
  margin-top: 0px;
}

.toggle-btns {
  margin: 25px auto;
  text-align: center;
}

.admission-page .form-page {
  width: 100%;
}

.before {
  margin-right: 25px;
}

@media (max-width: 600px) {
  #login-page,
  #admission-page {
    .form-page {
      width: 90%;
    }
  }
}

/* ======================== end login and admission Form ========================*/
</style>
