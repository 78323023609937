<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-progress-circular
          v-if="paymentStatus == ''"
          :size="70"
          :width="7"
          color="gray"
          indeterminate
        ></v-progress-circular>
        <p v-if="paymentStatus == 'paid'" class="success-payment">
          Successful Operation
        </p>
        <p v-if="paymentStatus == 'failed'" class="failed-payment">
          Operation Failed
        </p>
        <p>Redirecting to Wallet Page in {{ count }} seconds</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HttpService from "../services/HttpService.js";
export default {
  name: "PyamentRedirect",

  data() {
    return {
      paymentStatus: "",
      count: 3,
    };
  },
  methods: {
    verifyTransactionStatus() {
      HttpService.get(
        "wallet/recharge/verify/" + this.paymentId,
        "?moyaserPaymentId=" + this.moyaserPaymentId,
        true
      ).then((response) => {
        this.paymentStatus = response.data.data.paymentStatus;
        this.counter();
      });
    },
    counter() {
      setInterval(() => {
        if (this.count == 0) {
          this.$router.push("/points");
        }
        this.count = this.count - 1;
      }, 1000);
    },
  },

  mounted() {
    this.paymentId = this.$route.params.paymentId;
    this.moyaserPaymentId = this.$route.query.id;
    this.verifyTransactionStatus();
  },
};
</script>

<style>
.success-payment {
  font-size: 60px;
  color: green;
}
.failed-payment {
  font-size: 60px;
  color: red;
}
</style>
